<template>
  <div class="oilwax-history flex flex-column gap-4">
    <div class="flex items-center justify-between gap-2 w-100">
      <div class="flex gap-8">
        <date-range
          v-model="filters"
          @input="fetchRecords()"
        />
      </div>
    </div>

    <div class="font-bold">
      Showing {{ records.length | format }} / {{ recordCount | format }} record(s)
    </div>

    <table class="table table-google table-highlight table-invoices">
      <thead>
        <tr>
          <th>Date</th>
          <th>Brand</th>
          <th>Container</th>
          <th>Wax</th>
          <th>Dye</th>
          <th>Fragrance</th>
          <th>Additive 1</th>
          <th>Additive 2</th>
          <th>Count</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="isLoading || records.length === 0">
          <td colspan="4">
            <div class="font-bold">
              <span v-if="isLoading">Loading..</span>
              <span v-else-if="records.length === 0">No records found</span>
            </div>
          </td>
        </tr>

        <tr v-for="record in records" :key="record.id">
          <td class="row-fit">{{ record.print_date | isodate }}</td>
          <td class="row-fit text-nowrap">{{ record.candle_brand_text }}</td>
          <td class="text-nowrap">
            <div class="flex gap-2" v-if="record.container_text">
              <div v-if="record.container_text">{{ getContainerText(record.container_text) }}</div>
              <div v-if="record.container_mass">({{ record.container_mass }} g)</div>
            </div>
          </td>
          <td class="text-nowrap">{{ record.wax_text }}</td>
          <td class="text-nowrap">
            <div v-if="record.colorant === 0">
              None
            </div>
            <div v-else class="flex gap-1">
              <div v-if="record.colorant_text">{{ record.colorant_text }}</div>
              <div v-if="record.colorant_pct">({{ record.colorant_pct }}%)</div>
            </div>
          </td>
          <td class="text-nowrap">
            <div class="flex gap-1">
              <div v-if="record.fragrance_text">{{ record.fragrance_text }}</div>
              <div v-if="record.fragrance_pct">({{ record.fragrance_pct_text }}%)</div>
            </div>
          </td>
          <td class="text-nowrap">
            <div class="flex gap-1">
              <div v-if="record.additive1_text">{{ record.additive1_text }}</div>
              <div v-if="record.additive1_pct_text">({{ record.additive1_pct_text }}%)</div>
            </div>
          </td>
          <td class="text-nowrap">
            <div class="flex gap-1">
              <div v-if="record.additive2_text">{{ record.additive2_text }}</div>
              <div v-if="record.additive2_pct_text">({{ record.additive2_pct_text }}%)</div>
            </div>
          </td>
          <td class="text-nowrap">
            <div class="flex gap-1">
              <div v-if="record.candle_count">{{ record.candle_count }}</div>
            </div>
          </td>
          <td class="row-fit">
            <div class="flex gap-2">
              <b-button
                variant="white"
                size="sm"
                @click="showSds(record)"
              >
                SDS
              </b-button>

             <b-button
                variant="primary"
                size="sm"
                @click="showRecord(record)"
              >
                Edit
              </b-button>

              <b-button
                variant="danger"
                size="sm"
                @click="deleteRecord(record)"
              >
                Delete
              </b-button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <b-pagination
      v-model="filters.page"
      :total-rows="recordCount"
      :per-page="filters.per_page"
      @input="fetchRecords(true)"
      :disabled="isLoading"
    ></b-pagination>

    <sds-modal
      v-if="sdsModal.showModal"
      :data="sdsModal"
    />
  </div>
</template>

<script>
const DateRange = () => import('@/components/invoices/DateRange.vue');
const SdsModal = () => import('@/components/oilwax_calculator/SdsModal.vue');

export default {
  name: 'History',
  components: {
    DateRange,
    SdsModal,
  },
  computed: {
    isLoading() {
      return this.loadingCount > 0;
    },
  },
  data() {
    return {
      loadingCount: 0,
      records: [],
      recordCount: 0,
      filters: {
        date_from: null,
        date_to: null,
        page: 1,
        per_page: 50,
      },
      options: {},
      sdsModal: {
        showModal: false,
        id: null,
      },
    };
  },
  methods: {
    getContainerText(containerText) {
      const parts = containerText.split('|');
      if (parts.length >= 2) {
        return parts[1];
      }
      return containerText;
    },
    fetchRecordData() {
      this.loadingCount++;
      this.$http
        .get('/oilwax')
        .query(this.filters)
        .then((res) => {
          this.records = res.body.records;
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch records: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    fetchRecordCount() {
      this.loadingCount++;
      this.$http
        .get('/oilwax_count')
        .query(this.filters)
        .then((res) => {
          this.recordCount = res.body.count;
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch record count: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    fetchRecords(paginate) {
      paginate = paginate === undefined ? false : paginate;
      if (paginate === false) {
        this.fetchRecordCount();
        this.filters.page = 1;
      } else {
        setTimeout(() => {
          window.scrollTo(0, 0);
        }, 50);
      }
      this.fetchRecordData();
    },
    showRecord(record) {
      this.$emit('load', record.id);
    },
    showSds(record) {
      this.sdsModal.showModal = true;
      this.sdsModal.id = record.id;
    },
    deleteRecord(deleteRecord) {
      if (!confirm('Do you really wish to delete this record?')) {
        return;
      }
      this.loadingCount++;
      this.$http
        .delete(`/oilwax/${deleteRecord.id}`)
        .then(() => {
          this.records = this.records.filter((record) => record.id !== deleteRecord.id);
          this.recordCount--;
        })
        .catch((err) => {
          this.$toast.error(`Failed to delete record: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
